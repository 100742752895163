module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/gatsby/gatsby-citius-2023/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/corporative/citius_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48c61dcbae8739fb6871eae534c8bdb5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://citius.gal/a","siteUrl":"https://citius.gal","matomoPhpScript":"check.php","matomoJsScript":"check.js","exclude":["/offline-plugin-app-shell-fallback/"],"requireConsent":false,"requireCookieConsent":false,"disableCookies":true,"cookieDomain":"*.citius.gal","localScript":null,"dev":false,"enableJSErrorTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","gl"],"defaultLanguage":"en","siteUrl":"https://citius.gal","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","es","gl"],"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
