import * as React from "react";
import { NotDesktopView, DesktopView } from "components/Mediaqueries";
import NavMobile from "./NavMobile";
import NavDesktop from "./NavDesktop";

const Nav = () => (
  <>
    <NotDesktopView>
      <NavMobile />
    </NotDesktopView>
    <DesktopView>
      <NavDesktop />
    </DesktopView>
  </>
);

export default Nav;
