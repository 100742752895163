import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";
import "./cookiesConsent.module.scss";

const CookiesConsent = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="bottom"
      cookieName="cookieconsent"
      containerClasses="CookieConsent"
      contentClasses="CookieConsent_content"
      buttonWrapperClasses="CookieConsent_buttons"
      buttonText={t("cookiesConsent.acceptButton")}
      expires={150}
    >
      {t("cookiesConsent.text")}
    </CookieConsent>
  );
};

export default CookiesConsent;
