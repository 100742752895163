import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import * as style from "./accordion.module.scss";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Accordion = ({ id, title, content, className, defaultClosed, isMobileMenu, ...other }) => {
  const [isOpen, setIsOpen] = useState(!defaultClosed && true);
  const toggleAccordion = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }
  const toggleAccordionKey = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      setIsOpen(!isOpen);
    }
  }
  return (
    <div
      className={`
        ${style.Accordion}
        ${className ? className : ""}
      `.trim()}
      {...other}
    >
      <div className={style.Accordion_toggler}>
        <a onClick={toggleAccordion} onKeyUp={toggleAccordionKey} href={id ? "#" + id : ""}>
          <span className={`
            ${style.Accordion_title}
            ${isMobileMenu ? style.Accordion_titleMobileMenu : style.Accordion_title}
          `.trim()}>{title}</span>
          <span className={style.Accordion_arrow}>
            {isOpen ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </span>
        </a>
      </div>
      {isOpen && <div className={style.Accordion_content}>{content}</div>}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  className: PropTypes.string,
  defaultClosed: PropTypes.bool,
  isMobileMenu: PropTypes.bool,
};

Accordion.displayName = "Accordion";
