import * as React from "react";
import "styles/globals.scss";
import * as style from "./layout.module.scss";
import Nav from "components/Nav";
import Footer from "components/Footer";
import CookiesConsent from "components/CookiesConsent";
import BackToTop from "components/BackToTop";
import CitiusButton from "components/CitiusButton";

const Layout = ({ children }) => {
  return (
    <div className={style.Layout}>
      <Nav />
      {children}
      <Footer />
      <CookiesConsent />
      <BackToTop />
      <CitiusButton />
    </div>
  );
};

export default Layout;
