import * as React from "react";
import * as style from "./backToTop.module.scss";
import { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Link from "components/Link";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const BackToTop = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", updatePosition);
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  const heightTrigger = 5000;
  return (
    <>
      {scrollPosition > heightTrigger && (
        <Link
          to="#"
          className={style.BackToTop}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <span>{t("backToTop")}</span>
          <ArrowUpwardIcon />
        </Link>
      )}
    </>
  );
};

export default BackToTop;
