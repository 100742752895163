// extracted by mini-css-extract-plugin
export var LanguageSelector = "navDesktop-module--LanguageSelector--5626a";
export var NavBar = "navDesktop-module--NavBar--c6c96";
export var NavBar_logo = "navDesktop-module--NavBar_logo--6f630";
export var NavDesktop = "navDesktop-module--NavDesktop--c2161";
export var NavMenu = "navDesktop-module--NavMenu--bc87c";
export var NavMenu_link = "navDesktop-module--NavMenu_link--1a106";
export var NavSearch = "navDesktop-module--NavSearch--5c71e";
export var NavTopBar = "navDesktop-module--NavTopBar--18820";
export var slideIn = "navDesktop-module--slide-in--9cd09";
export var slideInFromRight = "navDesktop-module--slide-in-from-right--f88a5";