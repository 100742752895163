import * as React from "react";
import { useState } from "react";
import * as style from "./navMobile.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby";
import { navOptions, searchEverythingPath } from "./config.js";
import Link from "components/Link";
import Accordion from "components/Accordion";


import SearchIcon from "@mui/icons-material/Search";

import logoCitius from "images/corporative/logo_citius_tagline_horizontal.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const NavSearch = ({ searchValue, toggleNavMobile }) => {
  const { t } = useTranslation();
  const { language, defaultLanguage } = useI18next();

  function doSearch(event) {
    if (
      event.type === "click" ||
      (event.type === "keypress" && event.key === "Enter")
    ) {
      const value = document.getElementById("GlobalSearchInput").value;
      let path = searchEverythingPath;
      if (language !== defaultLanguage) {
        path = `/${language}${path}`;
      }
      navigate(path + "?search=" + encodeURI(value));
      toggleNavMobile();
    }
  }
  return (
    <div className={style.NavSearch}>
      <button type="button" onClick={(event) => doSearch(event)} aria-label={t("search")}>
        <SearchIcon />
      </button>
      <input
        id="GlobalSearchInput"
        type="search"
        placeholder={`${t("search")}...`}
        defaultValue={searchValue || ""}
        onKeyPress={(event) => doSearch(event)}
      />
    </div>
  );
};

const LanguageSelectorAndSearch = ({ toggleNavMobile }) => {
  const { languages, changeLanguage } = useI18next();
  return (
    <div className={style.LanguageSelector}>
      <NavSearch toggleNavMobile={toggleNavMobile} />
      <div className={style.LanguageSelectorSeparator}></div>
      {languages.map((lng) => {
        return (
          <button
            key={lng}
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(lng);
            }}
          >
            {lng}
          </button>
        );
      })}
    </div>
  );
};

const NavMenu = ({ toggleNavMobile }) => {
  const { t } = useTranslation();
  return (
    <div className={style.NavMenu}>
      {navOptions.map((option, index) => {
        if (option.submenu) {
          return (
            <Accordion
              key={uuidv4()}
              defaultClosed
              title={t(`${option.name}`)}
              className={style.NavMenu_accordion}
              isMobileMenu={true}
              content={
                <>
                  {option.submenu.map((suboption) => (
                    <Link
                      to={suboption.link}
                      key={uuidv4()}
                      onClick={toggleNavMobile}
                    >
                      {t(`${suboption.name}`)}
                    </Link>
                  ))}
                </>
              }
            />
          );
        }
        return (
          <Link
            to={option.link}
            key={option.link}
            className={style.NavMenu_link}
            onClick={toggleNavMobile}
          >
            {t(`${option.name}`)}
          </Link>
        );
      })}
      <LanguageSelectorAndSearch toggleNavMobile={toggleNavMobile} />
    </div>
  );
};

const NavMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavMobile = () => setIsOpen(!isOpen);
  return (
    <nav className={style.NavMobile}>
      <div className={style.NavMobile_navBar}>
        <Link to="/" className={style.NavMobile_logo}>
          <img src={logoCitius} alt="" />
        </Link>
        <button
          className={style.NavMobile_menuButton}
          onClick={toggleNavMobile}
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>
      {isOpen && <NavMenu toggleNavMobile={toggleNavMobile} />}
    </nav>
  );
};

export default NavMobile;
