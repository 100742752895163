import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as style from "./dropdown.module.scss";

const Dropdown = ({ trigger, menuItems, variant }) => {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (path[0] !== btnRef.current) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleClose);
    return () => {
      document.body.removeEventListener("click", handleClose);
    };
  }, []);
  return (
    <div
      className={`${style.Dropdown} ${
        variant ? style[`Dropdown___${variant}`] : ""
      }`.trim()}
    >
      <button
        className={style.Dropdown_trigger}
        ref={btnRef}
        onClick={handleOpen}
      >
        {trigger}
      </button>
      {isOpen ? <div className={style.Dropdown_menu}>{menuItems}</div> : null}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  trigger: PropTypes.node,
  menuItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  variant: PropTypes.oneOf(["topBar", "navMenu"]),
};

Dropdown.displayName = "Dropdown";
