// extracted by mini-css-extract-plugin
export var LanguageSelector = "navMobile-module--LanguageSelector--9f953";
export var LanguageSelectorSeparator = "navMobile-module--LanguageSelectorSeparator--afcaa";
export var NavMenu = "navMobile-module--NavMenu--34e06";
export var NavMenu_accordion = "navMobile-module--NavMenu_accordion--302a9";
export var NavMenu_link = "navMobile-module--NavMenu_link--5c865";
export var NavMobile = "navMobile-module--NavMobile--939aa";
export var NavMobile_logo = "navMobile-module--NavMobile_logo--c6c51";
export var NavMobile_menuButton = "navMobile-module--NavMobile_menuButton--7c7aa";
export var NavMobile_navBar = "navMobile-module--NavMobile_navBar--43673";
export var NavSearch = "navMobile-module--NavSearch--d829f";
export var slideIn = "navMobile-module--slide-in--3b27b";
export var slideInFromRight = "navMobile-module--slide-in-from-right--d9a73";