import { navigate } from "gatsby";

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      const userLanguage = localStorage.getItem('gatsby-i18next-language') || 'en';
      const currentPath = window.location.pathname;

      // Extract the first segment of the path to check for an existing language prefix
      const pathSegments = currentPath.split('/');
      const currentPrefix = pathSegments[1]; // First path segment after '/'

      const supportedLanguages = ['en', 'es', 'gl'];

      // Remove existing language prefix if it is different from the preferred language
      if (supportedLanguages.includes(currentPrefix) && currentPrefix !== userLanguage) {
        // Reconstruct path without the current prefix
        const pathWithoutPrefix = pathSegments.slice(2).join('/') || ''; 
        const newPath = (userLanguage === 'en') ? `/${pathWithoutPrefix}` : `/${userLanguage}/${pathWithoutPrefix}`;
        console.log(`Redirecting to: ${newPath}`);
        navigate(newPath);
      }
      // Redirect if no prefix is present and preferred language is not 'en'
      else if (!supportedLanguages.includes(currentPrefix) && userLanguage !== 'en') {
        const newPath = `/${userLanguage}${currentPath}`;
        console.log(`Redirecting to: ${newPath}`);
        navigate(newPath);
      }
    }, 100); // Short delay to ensure full page load
  }
};