import React, { useEffect, useState } from "react";
import axios from 'axios';

const CitiusButton = () => {
    const [inbounds, setInbounds] = useState(false);

    useEffect(() => {
        axios.get('https://citius.gal/inbounds')
        .then(response => {
            setInbounds(true);
        })
        .catch(error => {
            setInbounds(false);
        });

        if(inbounds){
            const script = document.createElement('script');
    
            script.src = "https://apps.citius.usc.es/bottomicon-citius/bottomicon-citius-domain.js";
            script.async = true;
    
            document.body.appendChild(script);
    
            return () => {
                document.body.removeChild(script);
            }
        }
    }, [inbounds]);
    
    return <></>;
};

export default CitiusButton;
